/** 默认每页显示条数 */
export const defaultPageSize = 10
/** 页码选择列表 */
// export const defaultPaginationOptions = ['10', '20', '50', '100', '500']
export const defaultPaginationOptions = {
  hideOnSinglePage: true,
  showQuickJumper: true,
  showSizeChanger: true,
  showLessItems: true,
  pageSizeOptions: ['10', '20', '50', '100', '500'],
  showTotal: (total: number) => {
    return `共 ${total} 条`
  },
}

// 文件上传相关的配置 --- 可能是需要根据环境进行区分的
// export const uploadConfig = {
//   imgUploadUrl: '/opapi/imgUpload',
//   editImgUploadUrl: '/opapi/editImgUpload',
// }

// console.log('process.env.REACT_APP_BASE_API-----', process.env.REACT_APP_BASE_API)
export const apiConfig = {
  // baseUrl: '',
  // baseUrl: '/api',
  baseUrl: process.env.REACT_APP_BASE_API,
  // baseUrl: 'http://ceibs.el.net',
  // baseUrl: 'https://api-dev.ceibsonline.cn',
}

let HOME_URL = process.env.REACT_APP_HOME_URL
// 本地或开发环境直接用当前域名
if (['development', 'localdev'].includes(process.env.REACT_APP_ENV || '')) {
  HOME_URL = window.location.origin
}
export const URLMAP = {
  login: HOME_URL,
}
