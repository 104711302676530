import React from 'react'
// import { Navigate } from 'react-router-dom'
import { getLoginUrl, getOrgInfo } from '@/utils/authority'
import { AuthorizedProps, checkAuth } from './index'
import NoPower from './NoPower'
const AuthorizedRouter: React.FC<AuthorizedProps> = ({ auth, children, noMatch = <NoPower /> }) => {
  const orgInfo = getOrgInfo()
  if (!(orgInfo && orgInfo.id)) {
    // return <Navigate to="/login" replace />
    window.location.href = getLoginUrl()
    return null
  } else if (checkAuth(auth)) {
    return <>{children}</>
  } else {
    return <>{noMatch}</>
  }
}

export default AuthorizedRouter
