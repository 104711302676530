import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
// import { createRoot } from 'react-dom/client'
import './assets/css/index.less'
import Main from './Main'
import reportWebVitals from './reportWebVitals'
// import './data/mock/api'

// Before react 18
ReactDOM.render(<Main />, document.getElementById('root'))
// //  After react 18
// const container = document.getElementById('root')
// const root = createRoot(container!) // createRoot(container!) if you use TypeScript
// root.render(<Main />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
