const NoMatch = () => {
  return (
    <div>对不起，您访问的页面不存在。</div>
    // <Result
    //   style={{ alignSelf: 'center', flex: 'auto' }}
    //   status="404"
    //   title="404"
    //   subTitle="对不起，您访问的页面不存在。"
    //   extra={
    //     <Button
    //       type="primary"
    //       onClick={() => {
    //         // window.location.replace('/')
    //         window.history.back()
    //       }}
    //     >
    //       返回
    //     </Button>
    //   }
    // />
  )
}
export default NoMatch
