import { Outlet, RouteObject } from 'react-router-dom'
import Authorized from '@/components/Authorized/router'
import NoMatch from './components/NoMatch'
import AutoNavigate from './components/AutoNavigate'
import HomePage from '@/pages/Home'
import loadable from '@loadable/component'

const Chat = loadable(() => import('@/pages/Chat'))

export interface DiyRouteObject extends RouteObject {
  icon?: any
  name?: string
  children?: DiyRouteObject[]
  target?: string
  /** 在menu中是否隐藏 */
  hideInMenu?: boolean
  /** 在menu中隐藏子节点 */
  hideChildrenInMenu?: boolean
  /** 访问当前菜单所需要的权限 */
  auth?: string | string[]
  /** 是否归属于某个路由之下，主要用于菜单高亮选中，暂时先备用，通过其他办法解决 */
  belongTo?: string
  /** 是否为autoNavigate节点 */
  autoNavigate?: boolean
  /** 是否为外部地址 */
  external?: boolean
}
const createRoutes = (routes: DiyRouteObject[]): DiyRouteObject[] => {
  const realRoutes = routes.map((route) => {
    const troute: DiyRouteObject = { ...route }
    // 存在子路由的话
    if (route.children) {
      troute.children = [...createRoutes(route.children)]
      // 如果没有outlest，表示没有chren渲染
      // 判断下孩子节点有没有index===true，没有的话就自动加一个
      const indexRoute = troute.children.find((r) => r.index === true)
      if (!indexRoute) {
        troute.children = [
          {
            index: true,
            element: <AutoNavigate canRoutes={route.children} />,
            autoNavigate: true,
            hideInMenu: true,
          },
          ...troute.children,
        ]
      }
    }
    // 没有权限的话跳转403,未登录跳转登录
    if (route.auth) {
      troute.element = <Authorized auth={route.auth}>{route.element ? route.element : <Outlet></Outlet>}</Authorized>
    }
    return troute
  })
  return realRoutes
}

// 模块路由
// export const moduleRoutes: DiyRouteObject[] = [...wxRoutes]

const allRoutes: DiyRouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/chat',
    element: <Chat />,
  },
  { path: '*', element: <NoMatch /> },
]
// console.log('allRoutes', allRoutes)
export const routes = createRoutes(allRoutes)
