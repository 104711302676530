/*
 * @Author: 王嘉炀
 * @Date: 2021-11-07 23:53:26
 */
import login from './modules/login'

export function createStore() {
  return {
    login,
  }
}

export const store = createStore()

export type TStore = ReturnType<typeof createStore>
