import React from 'react'
import { getOrgInfo } from '@/utils/authority'
// import { DiyRouteObject } from '@/routers/routes'
export interface AuthorizedProps {
  auth: string | string[] | undefined | null
  element?: React.ReactNode
  children?: React.ReactNode
  noMatch?: any
}
// 权限校验
export function checkAuth(auth: string | string[] | undefined | null) {
  // const userInfo = getUserInfo()
  // if (!userInfo) {
  //   return false
  // }
  // const { powersMap } = userInfo
  // if (!powersMap) {
  //   return false
  // }
  // if (!auth) {
  //   return true
  // }
  // if (typeof auth === 'string') {
  //   // if (authList.includes(auth)) return true
  //   if (powersMap.hasOwnProperty(auth)) return true
  // } else if (Array.isArray(auth)) {
  //   // 如果是数组
  //   for (let a of auth) {
  //     if (powersMap.hasOwnProperty(a)) return true
  //   }
  // }
  return false
}

// 校验数据范围权限
export function checkScopeAuth(auth: string | null | string[], authGroup?: Record<string, any> | null | undefined) {
  if (!auth || !authGroup) return false
  if (Array.isArray(auth)) {
    return auth.some((item) => {
      return authGroup[item]
    })
  }
  return !!authGroup[auth]
}

// ;<Result
//   style={{ alignSelf: 'center', flex: 'auto' }}
//   status="403"
//   title="403"
//   subTitle="Sorry, you are not authorized to access this page."
// />

const Authorized: React.FC<AuthorizedProps> = ({ auth, children, noMatch = null }) => {
  const orgInfo = getOrgInfo()
  if (!(orgInfo && orgInfo.id)) {
    return null
  } else if (checkAuth(auth)) {
    return <>{children}</>
  } else {
    return <>{noMatch}</>
  }
}

export default Authorized
