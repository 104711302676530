export default () => {
  return (
    // <Result
    //   style={{ alignSelf: 'center', flex: 'auto' }}
    //   status="403"
    //   title="403"
    //   subTitle="对不起，您暂无权限访问，请联系管理员。"
    //   extra={
    //     <Button
    //       type="primary"
    //       onClick={() => {
    //         window.history.back()
    //       }}
    //     >
    //       返回
    //     </Button>
    //   }
    // />
    <div>对不起，您暂无权限访问，请联系管理员</div>
  )
}
