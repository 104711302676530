import RenderRouter from '@/routers/RenderRouter'
/**
 * 这边要做一些数据预加载
 * 如果缓存中存在用户信息&&路由页面不是login页面，则直接触发获取用户信息做登录
 * @returns
 */
function App() {
  return <RenderRouter></RenderRouter>
}
export default App
