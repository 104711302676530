import { OrgInfo } from '@/@types'
import { URLMAP } from '@/configs/app.config'

// 获取师资机构信息
export function getOrgInfo(): OrgInfo {
  const orginfo = localStorage.getItem('eceibs-sz-orginfo')
  if (!orginfo) {
    return {}
  }
  try {
    return JSON.parse(orginfo)
  } catch (e) {
    return {}
  }
}
// 设置师资机构信息
export function setOrgInfo(orginfo: OrgInfo) {
  return localStorage.setItem('eceibs-sz-orginfo', JSON.stringify(orginfo))
}
// 清除师资机构信息
export function clearOrgInfo() {
  return localStorage.removeItem('eceibs-sz-orginfo')
}

export function getLoginUrl(redirectUrl: string = window.location.href) {
  return `${URLMAP.login}?returnUrl=${encodeURIComponent(redirectUrl)}`
}
