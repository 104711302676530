import { configure, makeAutoObservable } from 'mobx'
import { getOrgInfo } from '@/utils/authority'
import { OrgInfo } from '@/@types'
// import { URLMAP } from '@/configs/app.config'

// 不允许在动作外部修改状态
configure({ enforceActions: 'always', useProxies: 'never' })

class Login {
  constructor() {
    // makeObservable(this)
    makeAutoObservable(this)
  }
  // 默认是先从登录信息中获取
  orgInfo: OrgInfo | null = getOrgInfo()
}
const loginStore = new Login()
export default loginStore
