import React, { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import { DiyRouteObject } from '../routes'
import { checkAuth } from '@/components/Authorized'
const AutoNavigate: React.FC<{ canRoutes: DiyRouteObject[] }> = (props) => {
  const { canRoutes } = props
  const navigate = useNavigate()

  // 取第一层route,第一个route,并且是要有权限的，进行重定向
  useEffect(() => {
    // let route = canRoutes.find(
    //   (r) => r.path && typeof r.path === 'string' && checkAuth(r.meta?.auth)
    // )

    // if (route?.children && route.children.length > 0) {
    //   route = route.children.find(
    //     (r) => r.path && typeof r.path === 'string' && checkAuth(r.meta?.auth)
    //   )
    // }
    // if (route?.children && route.children.length > 0) {
    //   route = route.children.find(
    //     (r) => r.path && typeof r.path === 'string' && checkAuth(r.meta?.auth)
    //   )
    // }
    const route = deepIndex(canRoutes)
    if (route) {
      if (route.path) {
        navigate(route.path as string, { replace: true })
      } else {
      }
    } else {
      // 不存在跳登录
      navigate('/403', { replace: true })
    }
  }, [canRoutes, navigate])

  function deepIndex(routes: DiyRouteObject[]): DiyRouteObject | undefined {
    let route = routes.find((r) => r.path && typeof r.path === 'string' && checkAuth(r.auth))
    if (route?.children && !(route.children[0].index === true && route.children[0].autoNavigate !== true)) {
      route = deepIndex(route.children)
    }
    return route
  }
  return <Outlet></Outlet>
}
export default AutoNavigate
